import 'styles/global.css'
import 'quill/dist/quill.bubble.css'
import 'worlds-phone-number-input/style.css'
import '@brainhubeu/react-carousel/lib/style.css'
import 'react-circular-progressbar/dist/styles.css'
import 'draft-js/dist/Draft.css'

import { fetcher } from '@momenthouse/api'
import {
  darkTheme,
  globalStyles,
  lightTheme,
  ThemeProvider,
  ToastProvider,
} from '@momenthouse/ui'
import { Analytics } from '@vercel/analytics/react'
import ErrorBoundary from 'components/ErrorBoundary'
import ErrorFallback from 'components/ErrorFallback'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import type { ReactElement, ReactNode } from 'react'
import { SWRConfig } from 'swr'

const ToastController = dynamic(
  () =>
    import('@momenthouse/ui').then(({ ToastController }) => ToastController),
  { ssr: false }
)

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  globalStyles()
  const getLayout = Component.getLayout ?? ((page) => page)
  return (
    <ThemeProvider
      enableSystem={false}
      defaultTheme="light"
      attribute="class"
      value={{
        dark: darkTheme.className,
        light: lightTheme.className,
      }}
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ToastProvider>
          {getLayout(<Component {...pageProps} />)}
          <Analytics />
          <ToastController />
        </ToastProvider>
      </ErrorBoundary>
    </ThemeProvider>
  )
}
